import React from "react";
import Page from '../Page';
import { BrowserView, MobileView } from "react-device-detect";
import TableauReport from 'tableau-react';

const TeamUsage = (props) => {
    const url = process.env.NODE_ENV === "development" || window.location.hostname === "dev.databallanalytics.com" ?
        "https://public.tableau.com/views/TeamUsageDEV/Targets" :
        "https://public.tableau.com/views/TeamUsagePROD/Targets";

    return (
        <Page>
            <BrowserView>
                <div className="dashboard" style={{ height: "1000px", width: "1000px", paddingLeft: "0px" }}>
                    <TableauReport url={url} options={{ height: "1500px", width: "1000px" }} />
                </div>
            </BrowserView>
            <MobileView>
                <div className="dashboard" style={{ height: "1000px", width: "1000px", paddingLeft: "0px" }}>
                    <TableauReport url={url} options={{ height: "1500px", width: "1000px" }} />
                </div>
            </MobileView>
        </Page>
    );
};

export default TeamUsage;