import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import Http from '../classes/Http';
import { UserType, EmptyUser } from '../classes/UserType';

export type UserContextType = {
    user: UserType | null;
    userFetched: boolean,
    updateUser: (user: UserType) => void;
}

export const UserContext = React.createContext<UserContextType>({
    user: EmptyUser,
    userFetched: false,
    updateUser: (user: UserType) => {}
});

const UserContextProvider = (props) => {
    const Auth0 = useAuth0();
    const [user, setUser] = useState(EmptyUser);
    const [userFetched, setUserFetched] = useState(false);

    const updateUser = (_user: UserType) => {
        const newUser = Object.assign({}, user, _user);
        if (!_.isEqual(newUser, user)) {
            // merge args with user
            setUser(newUser);
            //persist in api store, only send updates
            Http.put(Auth0, `${process.env.REACT_APP_API_URL}/users/me`, _user).then((res) => {
                // reset with latest from api
                if (!_.isEqual(res.data, newUser)) {
                    setUser(res.data);
                }
            }).catch((ex) => {
                console.error(ex.message);
            });
        }
    };

    // fetch user on login
    useEffect(() => {
        if (!userFetched && Auth0.isAuthenticated) {
            Http.get(Auth0, `${process.env.REACT_APP_API_URL}/users/me`).then((res) => {
                setUser(res.data);
                setUserFetched(true);
            }).catch((ex) => {
                console.error(ex.message);
                setUser(EmptyUser);
                setUserFetched(true);
            });
        }
    }, [Auth0, Auth0.isAuthenticated, userFetched]);
  
    return (
      <UserContext.Provider value={{user, userFetched, updateUser}}>
          {props.children}
      </UserContext.Provider>
    )
}

export default UserContextProvider;