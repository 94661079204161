import React from "react";
import Page from '../Page';
import { BrowserView, MobileView } from "react-device-detect";
import TableauReport from 'tableau-react';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import Tour from 'reactour'

const DefensiveRankings = (props) => {
    const url = process.env.NODE_ENV === "development" || window.location.hostname === "dev.databallanalytics.com" ?
        "https://public.tableau.com/views/DefensiveRankingsDEV/Rankings" :
        "https://public.tableau.com/views/DefensiveRankingsPROD/Rankings";

    const [openTutorial, setTutorialOpen] = React.useState(false);
    const stepsBrowser = [
        {
            selector: '#tour_1',
            content: 'This is my first step',
          },
          {
            selector: '#tour_2',
            content: 'This is my second step',
          },
          {
            selector: '#tour_3',
            content: 'This is my third step',
          }
    ];
    const stepsMobile = [
        {
            selector: '#tour_1',
            content: 'This is my first step',
          },
          {
            selector: '#tour_2',
            content: 'This is my second step',
          },
          {
            selector: '#tour_3',
            content: 'This is my third step',
          }
    ];

    return (
        <Page>
            <IconButton aria-label="help" onClick={() => setTutorialOpen(true)} color="default" className="helpIcon">
              <HelpOutlineRounded />
            </IconButton>
            <BrowserView>
                <div className="dashboard" style={{ height: "1100px", width: "940px", paddingLeft: "213px" }}>
                    <TableauReport url={url} options={{ height: "1800px", width: "670px" }} />
                    <Tour steps={stepsBrowser} isOpen={openTutorial} onRequestClose={() => setTutorialOpen(false)} rounded={7} />
                    {(openTutorial === true && (
                        <div>
                            <div id="tour_1" className="tour_step" style={{left:80, top:100, width:150, height:100}}></div>
                            <div id="tour_2" className="tour_step" style={{left:100, top:170, width:100, height:100}}></div>
                            <div id="tour_3" className="tour_step" style={{left:560, top:20, width:800, height:350}}></div>
                        </div>
                    ))}    
                </div> 
            </BrowserView>
            <MobileView>
                <div className="dashboard" style={{ height: "1100px", width: "570px" }}>
                    <TableauReport url={url} options={{ height: "1800px", width: "570px" }} />
                    <Tour steps={stepsMobile} isOpen={openTutorial} onRequestClose={() => setTutorialOpen(false)} rounded={7} />
                    {(openTutorial === true && (
                        <div>
                            <div id="tour_1" className="tour_step" style={{left:10, top:170, width:100, height:100}}></div>
                            <div id="tour_2" className="tour_step" style={{left:100, top:210, width:90, height:30}}></div>
                            <div id="tour_3" className="tour_step" style={{left:200, top:350, width:130, height:50}}></div>
                        </div>
                    ))} 
                </div>
            </MobileView>
        </Page>
    );
};

export default DefensiveRankings;