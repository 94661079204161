import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

const Auth = (props) => {
    const Auth0 = useAuth0();
    const { user, userFetched } = useContext(UserContext);

    if (Auth0.isAuthenticated && user && user.forceFeedback) {
        return <Redirect to="/feedback" />;
    }
    else if (Auth0.isAuthenticated && userFetched) {
        try {
            window['hj']('identify', Auth0.user.id);
        }
        catch (e) {
            
        }
   
        if (process.env.NODE_ENV === "development") {
            return <Redirect to="/debug" />;
        }
        else {
            return <Redirect to="/player_profile" />;
        }
    }

    return <div className="logging_in">Please wait while we log you in!</div>;
};

export default Auth;