import React from "react";
import Page from './Page';

const Feedback = (props) => {
    return (
        <Page>
            <iframe src="https://surveys.hotjar.com/8e06d94d-bf0d-46ad-804a-2c08ddee68e9" title="feedback" frameBorder={0} style={{border:0,width:"100%",height:"calc(100% - 6px)"}}></iframe>
        </Page>
    );
};

export default Feedback;