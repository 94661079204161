import React, { useState } from "react";
import INews from "../interfaces/News";
import dateFormat from "dateformat";
import ReactMarkdown from 'react-markdown';
import { isBrowser } from "react-device-detect";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';

interface Props extends React.Props<any> {
    news: INews
}

const News = (props: Props) => {
    const id = (new Date(props.news.date)).getTime();
    const [expanded,setExpanded] = useState(false);

    const createLinks = (msg) => {
        const dashs = ['Player Profile','Defensive Rankings','Team Usage','Opponent Rankings','Player Comparison'];

        for (let d of dashs) {
            msg = msg.replace(d,`[${d}](/${d.toLowerCase().replace(' ','_')})`);
        }

        return msg;
    };
    const onChange = (event, expanded) => {
        setExpanded(expanded);
    };

    return (
        <Accordion className="news" onChange={onChange}>
            <AccordionSummary expandIcon={<CommentRoundedIcon />}>
                <img className="news-headshot" alt="headshot" src={`assets/images/${props.news.author.toLowerCase().replace(' ', '_')}.jpg`} />
                {(isBrowser && (
                    <div className="news-author">{props.news.author.toUpperCase()}</div>
                ))}
                <div className="news-date">{dateFormat(new Date(props.news.date),"mmm. d, hh:mm TT").toUpperCase()}</div>
                <ReactMarkdown className="news-message">{createLinks(props.news.message)}</ReactMarkdown>
                <span className="disqus-comment-count" data-disqus-identifier={id}>&nbsp;</span>
            </AccordionSummary>
            <AccordionDetails>
                {(expanded && (
                    <iframe src={`comments.html?id=${id}&title=${props.news.message.substring(0, 40)}...`} height="500px" width="100%"></iframe>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default News;