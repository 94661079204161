import { PlayerType } from './PlayerType'

export type UserType = {
    test?: any,
    forceFeedback?: boolean,
    players?: PlayerType[]
};

export const EmptyUser:UserType = {
    test: null,
    forceFeedback: false,
    players: []
};