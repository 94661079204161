import React, { useEffect, useState } from "react";
import Page from './Page';
import { useAuth0 } from "@auth0/auth0-react";
import Helmet from 'react-helmet';
import http from "../classes/Http";
import INews from "../interfaces/News";
import News from "../components/News";
import { CircularProgress } from "@material-ui/core";

const HomeDefault = (props) => {
    const auth0 = useAuth0();
    const [news, setNews] = useState<INews[]>([]);
    
    useEffect(() => {
        http.get(auth0, `${process.env.REACT_APP_API_URL||""}/news`).then((res) => {
            setNews(res.data);
            //update disqus counts...
            setTimeout(() => {
                window['DISQUSWIDGETS'].getCount({reset: true});
            }, 1000);
            const interval = setInterval(() => {
                window['DISQUSWIDGETS'].getCount({reset: true});
            }, 60000);
            return () => clearInterval(interval);
        }).catch(()=> {
            
        });
    // eslint-disable-next-line
    }, []);

    return (
        <Page>
            <Helmet>
                <title>Databall</title>
                <meta name="description" content="Databall" />
                <meta name="keywords" content="Databall" />
            </Helmet>
            {((auth0.isLoading || !news.length) && (
                <CircularProgress />
            ))}
            {(!auth0.isLoading && news.length > 0 && (    
                <div className="news-outer">{news.map((n) => <News key={n.date} news={n} />)}</div>  
            ))}                    
        </Page>
    );
};

export default HomeDefault;