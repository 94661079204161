import React from "react";
import Page from '../Page';
import { BrowserView, MobileView } from "react-device-detect";
import TableauReport from 'tableau-react';

const PlayerProfile = (props) => {
    const url = process.env.NODE_ENV === "development" || window.location.hostname === "dev.databallanalytics.com" ?
            "https://public.tableau.com/views/PlayerProfileDEV/PlayerProfile" :
            "https://public.tableau.com/views/PlayerProfilePROD/PlayerProfile";
    return (
        <Page>
            <BrowserView>
                <div className="dashboard" style={{ height: "1454px", width: "1050px", paddingLeft: "0px" }}>
                    <TableauReport url={url} options={{ height: "1800px", width: "1050px" }} />
                </div>
            </BrowserView>
            <MobileView>
                <div className="dashboard" style={{ height: "1454px", width: "1050px", paddingLeft: "0px" }}>
                    <TableauReport url={url} options={{ height: "1800px", width: "1050px" }} />
                </div>
            </MobileView>
        </Page>
    );
};

export default PlayerProfile;