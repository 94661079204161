import React from "react";

const Footer = (props) => {

    return (
        <footer>
            
        </footer>
    );
};

export default Footer;