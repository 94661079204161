import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import { IconButton, InputLabel, ListItemSecondaryAction, ListItemText, MenuItem } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import Select from '@material-ui/core/Select';
import { CircularProgress } from "@material-ui/core";
import { PlayerType } from '../classes/PlayerType';
import Http from '../classes/Http';
import Page from "./Page";

const MyPlayers = (props) => {
    const Auth0 = useAuth0();
    const {user, updateUser} = useContext(UserContext);
    const [teams, setTeams] = useState<Array<string>>([]);
    const [players, setPlayers] = useState<Array<string>>([]);
    const [selTeam, setSelTeam] = useState<string>("");
    const [selPlayer, setSelPlayer] = useState<string>("");
    const [all_players, setAllPlayers] = useState<Array<PlayerType>>([]);
    const [my_players, setMyPlayers] = useState<Array<PlayerType>>([]);

    const handleTeamChange = (event) => {
        if (selTeam !== event.target.value) {
            setSelTeam(event.target.value);
        }
    };
    const handlePlayerChange = (event) => {
        if (selPlayer !== event.target.value) {
            setSelPlayer(event.target.value);
        }
    };

    const handleAddClick = () => {
        if (selPlayer) {
            const existingPlayer = my_players.filter(p => p.team === selTeam && p.name === selPlayer)[0];
            if (!existingPlayer) {
                const player = all_players.filter(p => p.team === selTeam && p.name === selPlayer)[0];
                //check if player already exists in my list
                if (player) {
                    const new_players = my_players.concat([player]);
                    setMyPlayers(new_players);
                    updateUser({
                        players: new_players
                    });
                }
            }
        }
    };

    const handleDeleteClick = (event, player) => {
        const left_players = my_players.filter(p => !(p.team === player.team && p.name === player.name));
        setMyPlayers(left_players);
        updateUser({
            players: left_players
        });
    };

    const handleRefreshClick = () => {
        //get players from api
        Http.get(Auth0, `${process.env.REACT_APP_API_URL}/players`).then((res) => {
            setAllPlayers(res.data);
        }).catch((ex) => {
            console.error(ex.message);
        });
    };

    useEffect(() => {
        if (all_players.length) {
            let _teams:string[] = [];
            //set teams
            all_players.forEach((p,i) => {
                if (_teams.indexOf(p.team) < 0) {
                    _teams.push(p.team);
                }
            });
            setTeams(_teams);
            //set players
            setSelPlayer("");
            setPlayers(all_players.filter((p) => {
                return p.team === selTeam;
            }).map((p) => {
                return p.name;
            }));
        }
    }, [all_players, selTeam]);

    useEffect(() => {
        if (user && Array.isArray(user.players)) {
            setMyPlayers(user.players);
        }
    }, [user]);

    useEffect(() => {
        //get players from api
        Http.get(Auth0, `${process.env.REACT_APP_API_URL}/players`).then((res) => {
            setAllPlayers(res.data);
        }).catch((ex) => {
            console.error(ex.message);
        });
    },[])

    return (
        <Page>
            {((Auth0.isLoading || !all_players.length) && (
                <CircularProgress />
            ))}
            {((!Auth0.isLoading && all_players.length > 0) && (      
                <List className="my-players">
                    <ListItem className="list-header">
                        <InputLabel id="select-team-placeholder">Select a Team</InputLabel>
                        <Select className="select-team" value={selTeam} onChange={handleTeamChange} id="select-team">
                            {teams.sort().map((t) => (
                                <MenuItem value={t}>{t}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel id="select-player-placeholder">Select a Player</InputLabel>
                        <Select className="select-player" value={selPlayer} onChange={handlePlayerChange} disabled={!selTeam}>
                            {players.sort().map((p) => {
                                return (<MenuItem value={p}>{p}</MenuItem>);
                            })}
                        </Select>
                        <RefreshIcon onClick={handleRefreshClick} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="add" onClick={handleAddClick}>
                                <AddIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {my_players.map((p) => (
                        <ListItem>
                            <ListItemText primary={p.name} secondary={p.team} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e,p)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            ))}
        </Page>
    );
};

export default MyPlayers;