import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexGrow: 1
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 0,
      textDecoration: "none",
      color: "white"
    },
    title_dark: {
      flexGrow: 0,
      textDecoration: "none",
      color: theme.palette.primary.main,
      position: "relative",
      left: "-65px"
    },
    tabs: {
      flexGrow: 1
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  });
});

const Header = (props) => {
  const Auth0 = useAuth0();
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const login = () => {
    Auth0.loginWithRedirect();
  }

  const logout = () => {
    Auth0.logout({
      "client_id": process.env.REACT_APP_AUTH_CLIENTID,
      "returnTo": `${window.location.protocol}//${window.location.host}`
    });
  }

  const handleDrawerOpen = () => {
    if (Auth0.isAuthenticated) {
      setDrawerOpen(!openDrawer);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
        })}
      >
        <Toolbar variant="dense" color="primary">
          <Grid container spacing={0} className="mobile">
            <Grid item xs={8}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, openMenu && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              {(openDrawer === false && ( 
                <Typography component={Link} to="/" variant="h6" className={classes.title} noWrap>
                  <img src="assets/images/football.png" alt="logo" style={{height: "25px", marginBottom:"-5px", paddingRight:"3px"}} />
                  Databall
                </Typography>
              ))}
            </Grid>  
            <Grid item xs={1}>
              
            </Grid>
            <Grid item xs={3}>
              {(Auth0.isAuthenticated && openDrawer === false && ( 
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  color="inherit"
                >
                  <img src={Auth0.user.picture} alt={Auth0.user.name} height={"25px"} />
                </IconButton>
              ))}
              {(Auth0.isAuthenticated && openDrawer === false && (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openMenu}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              ))}  
              {(!Auth0.isAuthenticated && openDrawer === false && ( 
                <Button variant="text" color="secondary" onClick={login} className="login">Log In</Button>
              ))}  
            </Grid> 
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader + " drawer-header"}>
          <Typography component={Link} to="/" variant="h6" className={classes.title_dark} noWrap>
            <img src="assets/images/football.png" alt="logo" style={{height: "25px", marginBottom:"-5px", paddingRight:"3px"}} />
            Databall
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider /> 
        {(Auth0.isAuthenticated && (
          <List>
            <ListItem button component={ Link } to="/" key={"News"} onClick={handleDrawerClose}>
              <ListItemText primary={"News"} />
            </ListItem>
            <ListItem button component={ Link } to="/my_players" key={"My Players"} onClick={handleDrawerClose}>
              <ListItemText primary={"My Players"} />
            </ListItem>
            <Divider /> 
            <ListItem className="nav-header">
              <ListItemText primary={"Dashboards"} />
            </ListItem>
            <Divider /> 
            <ListItem button component={ Link } to="/player_profile" key={"Player Profile"} onClick={handleDrawerClose}>
              <ListItemText primary={"Player Profile"} />
            </ListItem>
            <ListItem button component={ Link } to="/defensive_rankings" key={"Defensive Rankings"} onClick={handleDrawerClose}>
              <ListItemText primary={"Defensive Rankings"} />
            </ListItem>
            <ListItem button component={ Link } to="/team_usage" key={"Team Usage"} onClick={handleDrawerClose}>
              <ListItemText primary={"Team Usage"} />
            </ListItem>
            <ListItem button component={ Link } to="/opponent_rankings" key={"Opponent Rankings"} onClick={handleDrawerClose}>
              <ListItemText primary={"Opponent Rankings"} />
            </ListItem>
            <ListItem button component={ Link } to="/player_comparison" key={"Player Comparison"} onClick={handleDrawerClose}>
              <ListItemText primary={"Player Comparison"} />
            </ListItem>
          </List> 
        ))} 
      </Drawer>
    </React.Fragment>
  );
};

export default Header;