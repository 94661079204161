import React from "react";
import * as ReactSNAPSHOT from 'react-snapshot';
// import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from '@sentry/browser';

import './index.scss';

if (process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

ReactSNAPSHOT.render(<App />,document.getElementById("root"));

serviceWorker.unregister();