import axios from "axios";
import { Auth0ContextInterface } from "@auth0/auth0-react";

export default class Http {
    private static async getMgmntToken (auth0: Auth0ContextInterface) {
        return new Promise((res, rej) => {
            auth0.getAccessTokenSilently({
                audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
                scope: "openid profile email read:current_user update:current_user_metadata"
            }).then((accessToken) => {
                res(accessToken);
            }).catch(() => {
                return auth0.getAccessTokenWithPopup({
                    audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
                    scope: "openid profile email read:current_user update:current_user_metadata"
                }).then((accessToken) => {
                    res(accessToken);
                }).catch((ex) => {
                    rej(ex);
                });
            });
        });
    }

    private static async getAuthToken (auth0: Auth0ContextInterface) {
        return new Promise((res, rej) => {
            auth0.getAccessTokenSilently().then((accessToken) => {
                return auth0.getIdTokenClaims().then((claims) => {
                    res(claims.__raw);
                });
            }).catch(() => {
                return auth0.getAccessTokenWithPopup().then((accessToken) => {
                    return auth0.getIdTokenClaims().then((claims) => {
                        res(claims.__raw);
                    })
                }).catch((ex) => {
                    rej(ex);
                });
            });
        });
    }

    static async get (auth0: Auth0ContextInterface, url:string ,options?: object) {
        if (auth0.isAuthenticated && process.env.REACT_APP_AUTH_DOMAIN && url.indexOf(process.env.REACT_APP_AUTH_DOMAIN) > -1) {
            return this.getMgmntToken(auth0).then((accessToken) => {
                return axios.get(url, Object.assign({headers: { 'Authorization': `Bearer ${accessToken}` }}, options || {}));
            });
        }
        else if (auth0.isAuthenticated && process.env.REACT_APP_API_URL && url.indexOf(process.env.REACT_APP_API_URL) > -1) {
            return this.getAuthToken(auth0).then((accessToken) => {
                return axios.get(url, Object.assign({headers: { 'Authorization': `Bearer ${accessToken}` }}, options || {}));
            });
        }
        return axios.get(url, options);
    }

    static async put (auth0: Auth0ContextInterface, url:string, data?: any, options?: object) {
        if (auth0.isAuthenticated && process.env.REACT_APP_AUTH_DOMAIN && url.indexOf(process.env.REACT_APP_AUTH_DOMAIN) > -1) {
            return this.getMgmntToken(auth0).then((accessToken) => {
                return axios.put(url, data, Object.assign({headers: { 'Authorization': `Bearer ${accessToken}` }}, options || {}));
            });
        }
        else if (auth0.isAuthenticated && process.env.REACT_APP_API_URL && url.indexOf(process.env.REACT_APP_API_URL) > -1) {
            return this.getAuthToken(auth0).then((accessToken) => {
                return axios.put(url, data, Object.assign({ headers: { 'Authorization': `Bearer ${accessToken}` } }, options || {}));
            });
        }
        return axios.put(url, data, options);
    }

    static async post (auth0: Auth0ContextInterface, url:string, data?: any, options?: object) {        
        if (auth0.isAuthenticated && process.env.REACT_APP_AUTH_DOMAIN && url.indexOf(process.env.REACT_APP_AUTH_DOMAIN) > -1) {
            return this.getMgmntToken(auth0).then((accessToken) => {
                return axios.post(url, data, Object.assign({headers: { 'Authorization': `Bearer ${accessToken}` }}, options || {}));
            });
        }
        else if (auth0.isAuthenticated && process.env.REACT_APP_API_URL && url.indexOf(process.env.REACT_APP_API_URL) > -1) {
            return this.getAuthToken(auth0).then((accessToken) => {
                return axios.post(url, data, Object.assign({ headers: { 'Authorization': `Bearer ${accessToken}` } }, options || {}));
            });
        }

        return axios.post(url, data, options);
    }

    static async delete (auth0: Auth0ContextInterface, url:string, options?: object) {
        if (auth0.isAuthenticated && process.env.REACT_APP_AUTH_DOMAIN && url.indexOf(process.env.REACT_APP_AUTH_DOMAIN) > -1) {
            return this.getMgmntToken(auth0).then((accessToken) => {
                return axios.delete(url, Object.assign({headers: { 'Authorization': `Bearer ${accessToken}` }}, options || {}));
            });
        }
        else if (auth0.isAuthenticated && process.env.REACT_APP_API_URL && url.indexOf(process.env.REACT_APP_API_URL) > -1) {
            return this.getAuthToken(auth0).then((accessToken) => {
                return axios.delete(url, Object.assign({ headers: { 'Authorization': `Bearer ${accessToken}` } }, options || {}));
            });
        }

        return axios.delete(url, options);
    }
}