import React from "react";
import Page from '../Page';
import { BrowserView, MobileView } from "react-device-detect";
import TableauReport from 'tableau-react';

const PlayerComparison = (props) => {
    const url = process.env.NODE_ENV === "development" || window.location.hostname === "dev.databallanalytics.com" ?
        "https://public.tableau.com/views/PlayerComparisonDEV/PlayerComparison" :
        "https://public.tableau.com/views/PlayerComparisonDEV/PlayerComparison";

    return (
        <Page>
            <BrowserView>
                <div className="dashboard" style={{ height: "707px", width: "1300px", paddingLeft: "0px" }}>
                    <TableauReport url={url} options={{ height: "800px", width: "1400px" }} />
                </div>
            </BrowserView>
            <MobileView>
                <div className="dashboard" style={{ height: "707px", width: "1300px", paddingLeft: "0px" }}>
                    <TableauReport url={url} options={{ height: "800px", width: "1400px" }} />
                </div>
            </MobileView>
        </Page>
    );
};

export default PlayerComparison;