import React from "react";
import Page from '../Page';
import { BrowserView, MobileView } from "react-device-detect";
import TableauReport from 'tableau-react';

const OpponentRankings = (props) => {
    const url = process.env.NODE_ENV === "development" || window.location.hostname === "dev.databallanalytics.com" ?
        "https://public.tableau.com/views/OpponentComparisonDEV/OpponentComparison" :
        "https://public.tableau.com/views/OpponentComparisonPROD/OpponentComparison";

    return (
        <Page>
            <BrowserView>
                <div className="dashboard" style={{ height: "860px", width: "975px" }}>
                    <TableauReport url={url} options={{ height: "1500px", width: "975px" }} />
                </div> 
            </BrowserView>
            <MobileView>
                <div className="dashboard" style={{ height: "860px", width: "975px" }}>
                    <TableauReport url={url} options={{ height: "1500px", width: "975px" }} />
                </div>
            </MobileView>
        </Page>
    );
};

export default OpponentRankings;