import React from "react";
import Container from '@material-ui/core/Container';
import { BrowserView, MobileView } from "react-device-detect";

const Page = (props) => {
    return (
        <div className="page">
            <BrowserView>
                <Container className="browserRoot">{props.children}</Container>
            </BrowserView>
            <MobileView>
                <Container className="mobileRoot">{props.children}</Container>
            </MobileView>
        </div>
    );
};

export default Page;