import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { StripeProvider } from 'react-stripe-elements';
import UserContextProvider from "./contexts/UserContext";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Auth from "./components/Auth";
import Debug from "./pages/Debug";
import Home from "./pages/Home";
import Feedback from "./pages/Feedback";
import MyPlayers from "./pages/MyPlayers";
import DefensiveRankings from "./pages/dashboards/DefensiveRankings";
import TeamUsage from "./pages/dashboards/TeamUsage";
import OpponentRankings from "./pages/dashboards/OpponentRankings";
import PlayerProfile from "./pages/dashboards/PlayerProfile";
import PlayerComparison from "./pages/dashboards/PlayerComparison";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#026ed5',
      light: '#74b5f4'
    },
    secondary: {
      main: '#f23c41',
      light: '#fa8185'
    },
  },
});

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <Home />
    })}
    {...args}
  />
);

const App = (props) => {
  // const [ stripe, setStripe ] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_STRIPE_KEY) {
      // setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_KEY));
    }
  }, []);

  return (
    <Auth0Provider 
        domain={process.env.REACT_APP_AUTH_DOMAIN} 
        clientId={process.env.REACT_APP_AUTH_CLIENTID} 
        redirectUri={`${window.location.protocol}//${window.location.host}/auth`}
        useRefreshTokens={true}
        cacheLocation={"localstorage"}
        scope=""
    >
      {/* <StripeProvider stripe={stripe}> */}
        <MuiThemeProvider theme={theme}>
          <UserContextProvider>
            <BrowserRouter>
              <Header />
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/auth" exact component={Auth} />
                <Route path="/debug" exact component={Debug} />
                <ProtectedRoute path="/feedback" exact component={Feedback} />
                <ProtectedRoute path="/my_players" exact component={MyPlayers} />
                <ProtectedRoute path="/defensive_rankings" exact component={DefensiveRankings} />
                <ProtectedRoute path="/team_usage" exact component={TeamUsage} />
                <ProtectedRoute path="/opponent_rankings" exact component={OpponentRankings} />
                <ProtectedRoute path="/player_profile" exact component={PlayerProfile} />
                <ProtectedRoute path="/player_comparison" exact component={PlayerComparison} />
              </Switch> 
              <Footer />
            </BrowserRouter>
          </UserContextProvider>  
        </MuiThemeProvider>
      {/* </StripeProvider> */}
    </Auth0Provider>
  );
}

export default App;