import React, { useContext, useState } from "react";
import Page from './Page';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import Http from "../classes/Http";

const HomeDefault = (props) => {
    const Auth0 = useAuth0();
    const {user, updateUser} = useContext(UserContext);
    const [meta, setMeta] = useState({});

    const testUserUpdate = () => {
        const val = window.prompt("test val?");
        updateUser({ "test": val });
    }

    const testMetaGet = () => {
        Http.get(Auth0, `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${Auth0.user.sub}`).then((res) => {
            setMeta(res.data);
        }).catch((ex) => {
            console.log(ex.message);
        });
    }

    return (
        <Page> 
            <br/><pre>{JSON.stringify(Auth0, undefined, 2)}</pre>    
            <br/><Link to="#" onClick={testUserUpdate}>Update User</Link>   
            <br/><pre>{JSON.stringify(user, undefined, 2)}</pre>    
            <br/><Link to="#" onClick={testMetaGet}>Get Auth0 Meta</Link>
            <br/><pre>{JSON.stringify(meta, undefined, 2)}</pre>     
        </Page>
    );
};

export default HomeDefault;